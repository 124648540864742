<template>
  <v-container>
    <div class="mb-16">
      <ranking-table />
    </div>
  </v-container>
</template>

<script>
import RankingTable from '@/components/table/RankingTable'
export default {
  data: () => ({
    
  }),
  components:{
    RankingTable
  }
}
</script>

<style>

</style>