<template> 
  <v-footer
    dark 
  >
    <v-card
      flat
      tile
      class="lighten-1 white--text text-center"
    >
      <v-card-text>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4 white--text"
          icon
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
      BSCWrap - All Binance Smart Chain (BSC) Projects wrapped in one site!
      </v-card-text>
 <v-card-text class="white--text pt-0">
      <a href="mailto:support@bscwrap.com">support@bscwrap.com</a>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>BSC Wrap</strong>
      </v-card-text>
    </v-card>
  </v-footer>
  
</template>
<script>
export default {
   data: () => ({
      icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
    }) 

}
</script>
<style lang="css" scoped>

</style>

