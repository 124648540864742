<template>
  <v-container>
    <RPCServer />
  </v-container>
</template>

<script>
import RPCServer from '@/components/main/RPCServer'
export default {
  components: {
    RPCServer
  },
    metaInfo: {
      title: 'BSCWrap',
      titleTemplate: '%s - BSC RPC Servers',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    }
}
</script>

<style>

</style>