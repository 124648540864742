import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      themeCache: {
        get: key => localStorage.getItem(key),
        set: (key, value) => localStorage.setItem(key, value),
      }
    },
    dark: false,
    themes: {
      light: {
        primary: '#f3ba2e',  
        secondary: '#f3ba2e',  
        accent: '#f3ba2e',  
      },
      dark: {   
        primary: '#f3ba2e',  
        secondary: '#f3ba2e',  
        accent: '#f3ba2e',  
      },
    },
  },
})