<template>
  <div>
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h1>BToken</h1>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="btokenList"
        item-key="name"
        :items-per-page="1000"
        hide-default-footer
        :search="search"
        :loading="fetching"
      >
        <template v-slot:top>
          <v-text-field
          v-model="search"
          cache-items
          class="mx-4"
          flat
          hide-no-data
          hide-details
          label="Search"
          solo-inverted
          ></v-text-field>
        </template>
        <template v-slot:item.index="{ index }">
          {{ index + 1 }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    search: '',
    headers: [
      { text: '#', value: 'index' },
      { text: 'Token', value: 'symbol', class: 'text-h6'},
      { text: 'Used Market Cap', value: 'marketCapacity', align: 'center' },
      { text: 'Market Cap Change', value: 'marketPerChange', align: 'center' },
      { text: 'Used Amount', value: 'usedAmount', align: 'center' },
      { text: 'Amount Change', value: 'amountPerChange', align: 'center' },
      // { text: 'Last 7 Days amt', value: 'list', align: 'center', sortable: false },
      // { text: 'Add Date', value: 'percentChange', align: 'center' },
      // { text: 'Contact', value: 'percentChange', align: 'center', sortable: false },
    ],
    btokenList: [],
    fetching: false
  }),
  methods: {
    ...mapActions('BLOCKAPI', ['FETCH_BTOKEN_LIST']),
    async fetchBTokenList(){
      this.fetching = true
      try{
        let data = await this.FETCH_BTOKEN_LIST()
        this.btokenList = data.data
      }catch(e){
        console.log(e);
      }
      this.fetching = false
    },
  },
  created(){
    this.fetchBTokenList()
  }
}
</script>

<style>

</style>