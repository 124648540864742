var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"mx-auto"},[_c('v-card-title',[_c('h1',[_vm._v("Ranking")])]),_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.rankingList,"items-per-page":1000,"hide-default-footer":"","item-key":"name","search":_vm.search,"loading":_vm.fetching},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center flex-start"},[_c('img',{staticClass:"mx-1",attrs:{"src":require("@/assets/image/bsc.svg"),"width":"32","height":"32"}}),_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.token",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.token.percentChange)+" %")])]}},{key:"item.percentChange",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.percentChange)+" %")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }