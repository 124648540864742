<template>
  <v-container>
 
 <home-graph/>
      
 
    <div class="d-block d-sm-flex justify-space-between mt-3">
 
        <v-card fluid
          class="mx-auto text-center "  
        >
          <v-card-text>
            <div>Avg Gas Fee</div>
            <p class="text-h4 text--primary mt-4">
              $0.324723
            </p>
          </v-card-text>
        </v-card>
   
 
        <v-card
          class="mx-auto text-center" 
        >
          <v-card-text>
            <div>Total Address</div>
            <p class="text-h4 text--primary mt-4">
              47365898
            </p>
          </v-card-text>
        </v-card>
 
 
        <v-card
          class="mx-auto text-center" 
        >
          <v-card-text>
            <div>24H Txn</div>
            <p class="text-h4 text--primary mt-4">
              8469239
            </p>
          </v-card-text>
        </v-card>
   
    </div>
    <div class="mt-3 mb-16">
      <ranking-table />
    </div>
  </v-container>
</template>

<script>
import HomeGraph from '@/components/chart/Graph'
import RankingTable from '@/components/table/RankingTable'
export default {
  data: () => ({
    
  }),
  components:{
    HomeGraph,
    RankingTable
  }
}
</script>

<style scoped>
.chartDiv{
  width: 800px;  
  margin: 0 auto;
  border: 1px solid #cccc;
}
</style>