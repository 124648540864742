<template>
  <div>
    <div>
      <home-graph/>
    </div>
    <div class="mt-3 mb-16">
      <b-token-table />
    </div>
  </div>
</template>

<script>
import HomeGraph from '@/components/chart/Graph'
import BTokenTable from '@/components/table/BTokenTable'
export default {
  data: () => ({
    
  }),
  components:{
    HomeGraph,
    BTokenTable
  }
}
</script>

<style>

</style>