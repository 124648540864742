<template>
  <v-container>
    <GasPrice />
  </v-container>
</template>

<script>
import GasPrice from '@/components/main/GasPrice'
export default {
  components: {
    GasPrice
  },
    metaInfo: {
      title: 'BSCWrap',
      titleTemplate: '%s - BSC Gas Price',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    }
}
</script>

<style>

</style>