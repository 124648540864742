<template>
  <div class="mb-16">
    <r-p-c-server-table />
  </div>
</template>

<script>
import RPCServerTable from '@/components/table/RPCServerTable'
export default {
  components: {
    RPCServerTable
  }
}
</script>

<style>

</style>