<template>
<v-container>
    <project-details />
</v-container>
</template>

<script>
import ProjectDetails from '@/components/category/ProjectDetails'
 
export default {
  components: {
    ProjectDetails, 
  },
  data() { 
    console.log(this.$route.name)
    let projectName = this.$route.name
  },
      metaInfo: {
      title: 'BSCWrap',
      titleTemplate:  '%s - Project Details',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    }
 
}
</script>

<style>

</style>