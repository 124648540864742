<template>
  <div>
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h1>Gras Price</h1>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>