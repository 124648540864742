<template>
  <v-container>
    <div class="mb-16">
      <BToken />
    </div>
  </v-container>
</template>

<script>
import BToken from '@/components/main/BToken'
export default {
  components: {
    BToken
  },
    metaInfo: {
      title: 'BSCWrap',
      titleTemplate: '%s - BTokens Market Cap',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    }
}
</script>

<style>

</style>