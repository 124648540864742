<template>
  <div>
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h1>RPC Server</h1>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="serverList"
        item-key="name"
        :items-per-page="1000"
        hide-default-footer
        :search="search"
        :loading="fetching"
      >

        <template v-slot:item.ping="{ item }">
          <span>{{item.ping || '-'}} ms</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="tooltip">
            <v-btn
              class="mx-2 mt-2 mb-2"
              fab
              small
              @click="copyTextToClipboard(item.name)"
              @mouseover="mouseOut()"
            >
              <span class="tooltiptext" id="myTooltip">{{ToolTipTextPlace}}</span>
              <v-icon size="15">mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    search: '',
    headers: [
      { text: '', value: 'name', sortable: false},
      { text: '', value: 'ping', sortable: false},
      { text: '', value: 'actions', sortable: false}
    ],
    serverList: [],
    fetching: false,
    ToolTipTextPlace: 'Copy to clipboard',
  }),
  methods: {
    ...mapActions('BLOCKAPI', ['FETCH_SERVER_LIST']),
    async fetchServerList(){
      this.fetching = true
      try{
        let data = await this.FETCH_SERVER_LIST()
        this.serverList = data.data
        for(let i=0; i<this.serverList.length; i++){
          this.serverList[i].ping = await this.fetchPing(this.serverList[i].name)
        }
      }catch(e){
        console.log(e);
      }
      this.fetching = false
    },
    copyTextToClipboard(name) {
      const el = document.createElement('textarea');  
      el.value = name;                                 
      el.setAttribute('readonly', '');                
      el.style.position = 'absolute';                     
      el.style.left = '-9999px';                      
      document.body.appendChild(el);                  
      const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;                                    
      el.select();                                    
      document.execCommand('copy');                   
      document.body.removeChild(el);                  
      if (selected) {                                 
        document.getSelection().removeAllRanges();    
        document.getSelection().addRange(selected);   
      }
      
      this.ToolTipTextPlace = "Copied The Link"
    },
    mouseOut() {
      this.ToolTipTextPlace = "Copy to clipboard"
    },
    async fetchPing(url) {
      let start_time = new Date().getTime()
      let ping = null
      try {
        let data = await fetch(
          url
        ).then((data) => {
          ping = new Date().getTime() - start_time
        });
      } catch (e) {
        console.log(e);
      }
      return ping
    },
  },
  created(){
    this.fetchServerList()
  }
}
</script>

<style scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -75px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>