<template>
  <v-container> 
    <!-- <category-menu/>
     <side-bar />  -->
    <category-page/>
    <!--
    <div class="row">
      <div class="col-2">
        <side-bar />
      </div>
      <div class="col-10">
        <category-page />
      </div>
    </div>
    -->
  </v-container>
</template>

<script>
import SideBar from "@/components/sidebar/SideBar";
import CategoryMenu from '@/components/category/CategoryMenu'
import CategoryPage from '@/components/category/Category'
 
 
 
export default {
  components: {
    CategoryPage, 
    CategoryMenu 
  },
    metaInfo: {
      title: 'BSCWrap',
      titleTemplate: '%s - BSC Categories',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    }
}
</script>

<style>
</style>