<template>
  <div>
    <v-card
      class="mx-auto"
    >
      <v-card-title>
        <h1>Ranking</h1>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="rankingList"
        :items-per-page="1000"
        hide-default-footer
        item-key="name"
        :search="search"
        :loading="fetching"
      >
 
        <template v-slot:item.index="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.title="{ item }">
          
           
          <div class="d-flex align-center flex-start">
            <img class="mx-1" src="@/assets/image/bsc.svg" width="32" height="32"> 
            {{item.title}}
 
            </div>
        </template>
        <template v-slot:item.token="{ item }">
          <span>{{item.token.percentChange}} %</span>
        </template>
        <template v-slot:item.percentChange="{ item }">
          <span>{{item.percentChange}} %</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    headers: [
      { text: '#', value: 'index' },
      { text: 'Project', sortable: false, value: 'title'},
      { text: 'TVL', value: 'tvlShort', align: 'center' },
      { text: 'Address', value: 'addressShort', align: 'center' },
      { text: 'Txn', value: 'txnShort', align: 'center' },
      { text: 'Token', value: 'token', align: 'center', sortable: false },
      { text: 'Price Change', value: 'percentChange', align: 'center' },
    ],
    rankingList: [],
    fetching: false
  }),
  methods: {
    ...mapActions('BLOCKAPI', ['FETCH_RANKING_LIST']),
    async fetchRankingList(){
      this.fetching = true
      try{
        let data = await this.FETCH_RANKING_LIST()
        this.rankingList = data.data
      }catch(e){
        console.log(e);
      }
      this.fetching = false
    },
  },
  created(){
    this.fetchRankingList()
  }
}
</script>

<style>
table .v-image__image.v-image__image--cover{width:32px;height:32px;border-radius:8px;}
</style>