import Vue from 'vue'
import VueRouter from 'vue-router'

import AppLayout from '@/layout/AppLayout'
import Home from '@/views/Home.vue'
import Ranking from '@/views/Ranking.vue'
import BToken from '@/views/BToken.vue'
import RPCServer from '@/views/RPCServer.vue'
import GasPrice from '@/views/GasPrice.vue'

import Category from '@/views/Category.vue'
import ProjectDetails from '@/views/ProjectDetails.vue'

import SubmitForm from '@/components/form/SubmitForm'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'AppLayout',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: '/projects',
        name: 'Category',
        component: Category
      },
      {
        path: '/ranking',
        name: 'Ranking',
        component: Ranking
      },
      {
        path: '/btoken',
        name: 'BToken',
        component: BToken
      },
      {
        path: '/rpc-server',
        name: 'RPCServer',
        component: RPCServer
      },
      {
        path: '/gas-price',
        name: 'GasPrice',
        component: GasPrice
      },
      {
        path: '/category/:id/:name',
        name: 'Category',
        component: Category
      },
      {
        path: '/project/:name',
        name: 'ProjectDetails',
        component: ProjectDetails
      },
      {
        path: '/submit',
        name: 'SubmitForm',
        component: SubmitForm
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
