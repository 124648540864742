<template>
  <div>

  <v-app-bar
      app 
      dark
    >
 
        <v-toolbar-title> 
         <router-link to="/" class="d-flex align-center mx-3">
 
 <v-img width="150" height="100%" src="@/assets/logo/bscwrap-f.svg"></v-img>
        
  </router-link>
  </v-toolbar-title>
       
   <div class="hidden-sm-and-down">
          <v-btn v-for="item in menu" :key="item.icon" :to="item.link" flat>{{
            item.title
          }}</v-btn>
          </div>
  <v-spacer></v-spacer>
<theme-change />
        <v-menu class="hidden-md-and-up">
         <v-app-bar-nav-icon slot="activator" @click="drawer = true"></v-app-bar-nav-icon>
     
        </v-menu>
 
  </v-app-bar>
       <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      dark
    >
      <v-list
        nav
        dense
      >

 
        <v-list-item-group
          v-model="group"
          active-class="text--accent-4"
        >
     <v-list-item  v-for="item in menu" :key="item.icon" :to="item.link" >
            <v-list-item-icon>
              <v-icon>mdi-{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-title flat>{{
            item.title
          }}</v-list-item-title>
      </v-list-item>

        </v-list-item-group>

 </v-list>

 
<side-bar />
     
     
     
    </v-navigation-drawer>

<!-- 
    <v-app-bar
      app
      theme--dark
      class="
        v-bar--underline
        v-sheet
        theme--light
        v-toolbar v-toolbar--flat
        v-app-bar v-app-bar--clipped v-app-bar--fixed
      "
    >

     <div class="d-flex align-center" style="height:64px;">
  
 <router-link to="/" class="d-flex align-center mx-3 ">
 
 <v-img width="32" src="@/assets/logo/binance-coin-bnb-logo-1.png"></v-img>
        <h1 class="brand ml-1"> BSC Wrap </h1> 
  </router-link>
 </div>

      <div class="d-flex align-center justify-end d-none d-sm-flex right">
        <router-link
          class="v-btn v-btn--text theme--dark v-size--default"
          to="/"
          >Home</router-link
        >
        <router-link
          class="v-btn v-btn--text theme--dark v-size--default"
          to="/category/0/all"
          >Projects</router-link
        >
        <router-link
          class="v-btn v-btn--text theme--dark v-size--default"
          to="/ranking"
          >Ranking</router-link
        >
        <router-link
          class="v-btn v-btn--text theme--dark v-size--default"
          to="/btoken"
          >BToken</router-link
        >
        <router-link
          class="v-btn v-btn--text theme--dark v-size--default"
          to="/rpc-server"
          >RPC Server</router-link
        >
        <router-link
          class="v-btn v-btn--text theme--dark v-size--default"
          to="/gas-price"
          >Gas Price</router-link
        >
      </div>

      <div class="flex-1-1-auto d-flex align-center justify-end right">
        <v-btn rounded color="info" @click="$router.push('/submit')">
          <v-icon class="submit mr-1" @click="goTo(selectedProject.website)"
            >mdi-plus</v-icon
          >
          Submit Project
        </v-btn>

        <theme-change />
      </div>
    </v-app-bar>

      <div absolute right>
 
      <v-btn @click="$router.go(-1)"> <v-icon>mdi-arrow-left-bold</v-icon> Back</v-btn>
    </div>
 -->
<router-view />

<!--
    <div class="row">
      <div class="col-2">
        <side-bar />
      </div>

      <div class="col-8">
        <router-view />
      </div>

      <div class="col-2">
        <side-bar-right />
      </div>
    </div>
  -->

  <v-footer height="auto" color="dark" dark app bottom fixed padless>
        <v-layout justify-center>
          <v-flex dark py-2 text-xs-center white--text xs12 text-center>
            {{ new Date().getFullYear() }}  <strong>BSCWRAP</strong> — BINANCE SMART CHAIN WRAPPED PROJECTS — <a href="mailto:support@bscwrap.com">support@bscwrap.com</a>
    
          </v-flex>

        </v-layout>
      </v-footer>

  </div>
</template>

<script>
import SideBar from "@/components/sidebar/SideBar";
import ThemeChange from "@/components/sidebar/ThemeChange";
import Footer from "@/components/footer/Footer";
 
export default {
  components: {
    SideBar, 
    ThemeChange,
    Footer,
  },
  data: () => ({
      drawer: false,
      group: null,
        menu: [
        { icon: "home", title: "Home", link: "/" },
        { icon: "signal", title: "Projects", link: "/category/0/all" },
        { icon: "signal", title: "Ranking", link: "/ranking" },
        { icon: "chart-line", title: "BToken", link: "/btoken" },
        { icon: "server", title: "RPC Server", link: "/rpc-server" },
        { icon: "gas-station", title: "Gas Price", link: "/gas-price" },
      ]
    }),
 
   methods: {
    menuItems() {
      return this.menu;
    }
  }
};
</script>

<style>
a.v-btn.v-btn--is-elevated.v-btn--has-bg.v-btn--router.theme--light.v-size--default {
    box-shadow: none;
    background: inherit;
}
 
 /*
aside.v-navigation-drawer.v-navigation-drawer--fixed.theme--light {
    top: 90px !important;
    left: 50px;
    background: #f0f0f0 !important;
    height: auto !important;
    border-radius: 10px;
}

aside.v-navigation-drawer.v-navigation-drawer--fixed.v-navigation-drawer--floating.v-navigation-drawer--open.theme--light.v-navigation-drawer.v-navigation-drawer--open.theme--dark {
    top: 90px !important;
    left: 50px;
    background: #383838 !important;
    height: auto !important;
    border-radius: 10px;
}
*/
 
.mx-auto.text-center.v-card.v-sheet {
    width: 100%;
    margin: 0 10px !important;
}
.container {max-width: 1380px !important;}
a { text-decoration: none !important;}

.v-container { max-width:1320px}
.sticky-top {
    position: sticky;
    top: 100px;
}
.v-icon.v-icon.v-icon--link {
  cursor: pointer;
  outline: none;
  background: #f3ba2e47;
  color: #f3ba2e;
  border-radius: 100%;
  padding: 2px;
  font-size: 16px;
}
.v-btn.v-size--default,
.v-btn.v-size--large {
  font-size: 14px;
}
.theme--dark .v-bar--underline.theme--light,
.theme--light .v-bar--underline.theme--light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.theme--dark.v-list-item .v-list-item__action-text,
.theme--light.v-list-item .v-list-item__subtitle {
  color: #fff !important;
  font-size: 14px !important;
}
.theme--light.v-list-item .v-list-item__action-text,
.theme--light.v-list-item .v-list-item__subtitle {
  color: #000 !important;
  font-size: 14px !important;
}
.v-list-item__title.text-h4.mb-1 {
  font-weight: 700;
}
.v-list-item__title.text-h4.mb-1 {
  font-weight: 700;
}
.v-list-item__title.text-h5.mb-1 {
  font-weight: 700;
  font-size: 17px !important;
}

.v-card {
  box-shadow: none !important;
}
.v-navigation-drawer__prepend {
  background: #212121;
  color: #fff;
}
.v-navigation-drawer__content {
  background: #f2f2f2 !important;
}
 
.v-card__subtitle,
.v-card__text {
  letter-spacing: 0 !important;
  font-weight: 500;
}

.v-navigation-drawer .v-list:not(.v-select-list),
.v-navigation-drawer__content {
  /* background: inherit; */
  background: none !important;
}
/* .v-navigation, nav { background: #212121 !important;} */

.v-sheet.v-sheet--outlined {
  border: 1px solid #7c7c7c26;
  border-radius: 5px;
  padding: 20px;
}
.v-card__text {
  font-size: 16px !important;
  font-weight: 400 !important;
  word-break: break-word !important;
  line-height: 24px !important;
}
img,
.v-avatar.v-list-item__avatar.rounded-0.v-avatar--tile.grey {
  border-radius: 8px !important;
  background: none !important;
}
/*
 .container .v-list-item__title {
   font-weight:600 !important;
    color: #efb90a;
}
 
.v-list-item:hover > div, a.v-list-item--active { 
    color: #efb90a !important;
}
*/
h1.brand.ml-1 {
    text-decoration: none !important;
    color: #f3ba2e;
    font-weight: 400 !important;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
.theme--dark.v-btn.v-btn--has-bg {
    background-color: #121212;
    box-shadow: none;
}
/*
.v-card { 
  box-shadow: 2px 4px 12px rgb(0 0 0 / 8%) !important;
  border-radius: 18px !important;
}*/
</style>