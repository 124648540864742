import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import BLOCKAPI from '@/store/modules/blockchain.js'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    selectedProject: {},
    allCategoryList: []
  },
  getters: {
    
  },
  mutations: {
    setSelectedProject(state, payload) {
      state.selectedProject = payload
    },
    setAllCategoryList(state, payload) {
      state.allCategoryList = payload
    },
  },
  modules: {
    BLOCKAPI
  }
})
