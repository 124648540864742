<template>
  <div>
    <ranking />
  </div>
</template>

<script>
import Ranking from '@/components/main/Ranking'
export default {
  components: {
    Ranking
  },
    metaInfo: {
      title: 'BSCWrap',
      titleTemplate: '%s - BSC Ranking',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    }
}
</script>

<style>

</style>