<template>
 
 

      <apexchart :width="auto" height="350" type="line" :options="chartOptions" :series="series" class="v-sheet"></apexchart>
 
 
</template>

<script>
export default {
  data(){
    return {
      chartOptions: {
        chart: {
          id: 'vuechart',
          toolbar: {
            show: false,
          }
        },
        title: {
          text: '',
          margin: 10,
          style: {
            fontSize:  '24px',
            fontWeight:  'bold',
            color:  '#939393'
          },
        },
        subtitle: {
          text: '$0',
          marginTop: 10,
          style: {
            fontSize:  '36px',
            fontWeight:  'bold',
            color:  '#939393'
          },
        },
        yaxis: {
          labels: {
            show: false,
          }
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998 ,1999 ,2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022],
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          }
        },
        grid: {
          show: false
        }
      },
      series: [{
        name: '',
        data: [30, 40, 35, 50, 49, 60, 70, 91]
      }]
    }
  },
  methods: {
    setTextValue() {
      if(this.$route.name == 'BToken'){
        this.chartOptions.title.text = 'BTokens Market Cap'
      }else if(this.$route.name == 'Home'){
        this.chartOptions.title.text = 'Total Value Locked in BSC DeFi'
      }
    }
  },
  created(){
    this.setTextValue()
  }
}
</script>

<style scoped>

</style>