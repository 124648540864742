<template>
  <v-app id="app">
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  
  name: 'App',
  metaInfo: {
    title: '',
    titleTemplate: '',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'All Binance Smart Chain (BSC) Projects wrapped in one site!' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
 
</style>
