<template>
 

 
  <v-row>
  

    <v-col md="8">
      <v-card class="mx-auto v-card v-sheet">
        <v-list-item three-line>
          <v-list-item-avatar tile size="100">
            <img
              :src="
                'https://oss-us-cdn.maiziqianbao.net/bsc_project/' +
                selectedProject.img
              "
            />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h4 mb-1">
              {{ selectedProject.title }}
            </v-list-item-title>
            <v-list-item-subtitle>{{
              selectedProject.introduction
            }}</v-list-item-subtitle>
            <div class="mt-1">
              <v-chip outlined v-for="(tag, TI) in selectedProject.tags" :key="TI">
                {{ tag.name }}
              </v-chip>
            </div>
          </v-list-item-content>
        </v-list-item>

        <!-- <home-graph /> -->
      </v-card>

      <v-divider class="my-3"></v-divider>

      <v-card-title>About {{ selectedProject.title }}</v-card-title>
      <v-card-text>{{ selectedProject.introduction }}</v-card-text>
      <v-card-text>
        All info in this site is purely educational and should only be used to
        inform your own research. We're not offering investment advice,
        endorsement of any project or approach, or promise of any outcome.
      </v-card-text>
    </v-col>
    <v-col md="4">
      <div class="mb-5">

        <v-card-title> Links </v-card-title>

        <div class="mb-3 pointer" v-if="selectedProject.website" @click="goTo(selectedProject.website)">
           <v-icon class="mr-1" 
            >mdi-web</v-icon
          >
          {{selectedProject.website}}
        </div>
        <div class="mb-3 pointer" v-if="selectedProject.email" @click="goTo(selectedProject.email)">
          <v-icon class="mr-1" 
            >mdi-email</v-icon
          >
          E-mail
        </div>
        <div class="mb-3 pointer" v-if="selectedProject.twitter" @click="goTo(selectedProject.twitter)">
          <v-icon class="mr-1"
            >mdi-twitter</v-icon
          >
          Twitter
        </div>
        <div class="mb-3 pointer" v-if="selectedProject.telegram" @click="goTo(selectedProject.telegram)">
          <v-icon class="mr-1"
            >mdi-send</v-icon
          >
          Telegram
        </div>
        <div class="mb-3 pointer" v-if="selectedProject.github" @click="goTo(selectedProject.github)">
          <v-icon class="mr-1" 
            >mdi-github</v-icon
          >
          Github
        </div>
        <div class="mb-3 mt-3 pointer">
          <v-btn large class="ma-1" outlined rounded color="info" @click="goTo(selectedProject.website)">
            <v-icon class="mr-1" 
              >mdi-web</v-icon
            >
            Visit Wesite
          </v-btn>
          <v-btn
            large
            class="ma-1"
            outlined
            rounded
            color="success"
            @click="tradeBtn"
          >
            Trade
          </v-btn>

          <v-btn large class="ma-1" outlined rounded color="info" @click="goTo(selectedProject.website)">
            <v-icon class="pointer mr-1" 
              >mdi-share-variant</v-icon
            >
            Share
          </v-btn>
        </div>
      </div>

      <v-card-title> Publish Date </v-card-title>
      <v-card-text> NY </v-card-text>

      <v-card-title> Total Value Locked</v-card-title>
      <v-card-text> NY </v-card-text>

      <v-card-title> Address</v-card-title>
      <v-card-text> NY </v-card-text>

      <v-card-title> Txn</v-card-title>
      <v-card-text> NY </v-card-text>

      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import HomeGraph from "@/components/chart/Graph";

export default {
  components: {
    HomeGraph,
  },
  computed: {
    ...mapState(["selectedProject"]),
  },
  methods: {
    goTo(url) {
      window.open(url, "_blank");
    },
    tradeBtn() {
      let url =
        "https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x154a9f9cbd3449ad22fdae23044319d6ef2a1fab";
      window.open(url, "_blank");
    },
  },
  /*
  metaInfo: {
    title: "BSCWrap",
    titleTemplate: `%s - Project Details`,
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },ç
  */
};
</script>

<style scoped>
</style>