<template lang="html">
 

 

 <!-- 
     <v-navigation-drawer class="v-navigation-drawer v-navigation-drawer--fixed v-navigation-drawer--floating v-navigation-drawer--open theme--light">

 <div class="v-navigation-drawer__prepend">
 
 <div class="d-flex align-center" style="height:64px;">
  
 <router-link to="/" class="d-flex align-center mx-3 ">
 
 <v-img width="32" src="@/assets/logo/binance-coin-bnb-logo-1.png"></v-img>
        <h1 class="brand ml-1"> BSC Wrap </h1> 
        </router-link>
 </div>
 
 
</div>

-->
 
 
 
 
<!-- 
    <v-list nav dense>
      <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/ranking">
        <v-list-item-icon>
          <v-icon>mdi-signal</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Ranking</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/btoken">
        <v-list-item-icon>
          <v-icon>mdi-chart-line</v-icon>
        </v-list-item-icon>
        <v-list-item-title>BToken</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/rpc-server">
        <v-list-item-icon>
          <v-icon>mdi-server</v-icon>
        </v-list-item-icon>
        <v-list-item-title>RPC Server</v-list-item-title>
      </v-list-item>

      <v-list-item link to="/gas-price">
        <v-list-item-icon>
          <v-icon>mdi-gas-station</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Gas Price</v-list-item-title>
      </v-list-item>
    </v-list>

</v-navigation-drawer>

 -->
 
    <v-list nav dense>
 
      <v-list-item class="v-list-item v-list-item--link theme--light" link @click="allCategory()">
       <v-list-item-icon>
          <v-icon>mdi-alphabetical-variant</v-icon>
        </v-list-item-icon>
        <v-list-item-title>All Categories
          <strong class="pl-1" v-if="!fetching">{{projectCount[7].all}}</strong>
        </v-list-item-title>
      </v-list-item>

      <div class="d-flex justify-center align-center" v-if="fetching">
        <v-progress-circular class="mr-2" indeterminate color="" size="13" width="1.5" />
        <span>Fetching...</span>
      </div>
      <v-list-item class="v-list-item v-list-item--link theme--light" v-if="!fetching" v-for="(category, CI) in categoryList" :key="CI" link @click="goTo(category)">

       <v-list-item-icon v-if="category.name === 'Game'">
          <v-icon>mdi-controller-classic</v-icon>
        </v-list-item-icon>

        <v-list-item-icon v-if="category.name === 'Tooling'">
          <v-icon>mdi-hammer-screwdriver</v-icon>
        </v-list-item-icon>

        <v-list-item-icon v-if="category.name === 'DeFi'">
          <v-icon>mdi-finance</v-icon>
        </v-list-item-icon>

        <v-list-item-icon v-if="category.name === 'Community'">
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>
 

         <v-list-item-icon v-if="category.name === 'Workshop'">
          <v-icon>mdi-cog-clockwise</v-icon>
        </v-list-item-icon>

         <v-list-item-icon v-if="category.name === 'Wallet'">
          <v-icon>mdi-wallet-plus</v-icon>
        </v-list-item-icon>
 

         <v-list-item-icon v-if="category.name === 'Validator'">
          <v-icon>mdi-check-network</v-icon>
        </v-list-item-icon>

        

        <v-list-item-title>
          {{category.name}} 
          <strong class="pl-1">{{numberOfProject(category.id)}}</strong>
        </v-list-item-title>
      </v-list-item>
    </v-list>

 
      

 
</template>

<script>
import { mapActions } from 'vuex'
import ThemeChange from '@/components/sidebar/ThemeChange'

export default {
    components:{
    ThemeChange,
  },
  data() {
    return {
      fetching: false,
      categoryList: [],
      allCategoryData: [],
      projectCount: []
    }
  },
  methods: {
    ...mapActions('BLOCKAPI', ['FETCH_ALL_CATEGORIES', 'FETCH_CATEGORY_DETAILS', 'COUNTER']),
    async fetchAllCategories(){
      try{
        let data = await this.FETCH_ALL_CATEGORIES()
        this.categoryList = data.data
        for(let i=0;i<this.categoryList.length; i++){
          let catSingle = await this.docSingle(this.categoryList[i].id)
          for(let j=0;j<catSingle.data.length; j++){
            this.allCategoryData.push(catSingle.data[j])
          }
        }
      }catch(e){
        console.log(e);
      }
      this.$store.commit("setAllCategoryList", this.allCategoryData)
    },
    async docSingle(id) {
      let data = null
      try{
        data = await this.FETCH_CATEGORY_DETAILS(id)
      }catch(e){
        console.log(e);
      }
      if(data){
        return data
      }
    },
    goTo(category) {
      let route = "/category/"+ category.id + '/' + category.name
      this.$router.push(route)
    },
    allCategory(){
      let route = "/category/0/all"
      this.$router.push(route)
    },
    async totalDocNumber() {
      try{
        this.projectCount = await this.COUNTER()
        console.log(this.projectCount);
      }catch(e){
        console.log(e);
      }
    },
    async fetch() {
      this.fetching = true
      await this.fetchAllCategories()
      await this.totalDocNumber()
      this.fetching = false
    },
    numberOfProject(id) {
      return this.projectCount.find(v=> v.id == id).count
    }
  },
  created(){
    this.fetch()
  }
}
</script>

<style lang="css" scoped>
.brand { color:#fff; text-decoration: none !important; }
.v-application a {
    color: #1976d2;
    text-decoration: none;
}
.v-navigation-drawer__content { padding-top: 0 !important; margin-top: 0 !important;}
.submitBtn{
  cursor: pointer;
}
</style>
