<template>
  <div>
 
    <v-row>
      <div class="col-12">
    <v-text-field
        v-model="search" 
        label="Search"
        prepend-inner-icon="mdi-magnify"
        flat
          hide-no-data
          hide-details 
          solo-inverted
      ></v-text-field>
      </div>
    </v-row>

    <div class="d-flex align-center">
   <v-row>
      <div class="col-12">
        <v-chip outlined v-for="(tag, TI) in tagList" :key="TI" class="mx-1 my-1 pointer" @click="tagClick(tag.id)">{{tag.name}}</v-chip>
      </div>
    </v-row>
    </div>
    <v-divider></v-divider>
    <div class="mx-auto">
      <div class="d-flex justify-center align-center mt-3 xs3" v-if="fetching">
        <v-progress-circular class="mr-2" indeterminate color="" size="13" width="1.5" />
        <span>Fetching...</span>
      </div>
      <div v-else class="d-flex flex-wrap">
        <v-card 
           max-width="100%"
           min-width="100%"
          v-for="(category, CI) in filteredProject" :key="CI"
          class="mt-3 mx-3 pointer d-inline-block mx-auto xs3"
          @click="DetailsTab(category)"
        >
          <v-list-item three-line>
                        <v-list-item-avatar
              tile
              size="80"
              color="grey"
              
            ><img :src="'https://oss-us-cdn.maiziqianbao.net/bsc_project/' + category.img"></v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h5 mb-1">
                {{category.title}}
              </v-list-item-title>
              <div class="mb-5">
                <v-icon v-if="category.website" class="pointer mr-1" @click="goTo(category.website)">mdi-web</v-icon>
                <v-icon v-if="category.email" class="pointer mr-1" @click="goTo(category.email)">mdi-email</v-icon>
                <v-icon v-if="category.twitter" class="pointer mr-1" @click="goTo(category.twitter)">mdi-twitter</v-icon>
                <v-icon v-if="category.telegram" class="pointer mr-1" @click="goTo(category.telegram)">mdi-send</v-icon>
                <v-icon v-if="category.github" class="pointer mr-1" @click="goTo(category.github)">mdi-github</v-icon>
              </div>
              <v-list-item-subtitle>{{category.introduction}}</v-list-item-subtitle>
              <div class="mt-1">
                <v-chip outlined v-for="(tag, TI) in category.tags" :key="TI">
                  {{tag.name}}
                </v-chip>
              </div>
            </v-list-item-content>

          </v-list-item>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data() {
    return{
      categoryData: [],
      fetching: false,
      search: '',
      tagList: []
    }
  },
  watch: { 
    $route(to, from) { 
      if(to !== from){ 
        this.fetchCategoryDetails() 
      } 
    } 
  },
  computed: {
    ...mapState(['allCategoryList']),
    filteredProject(){
      return this.categoryData.filter(item =>{
        return item.title.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions('BLOCKAPI', ['FETCH_CATEGORY_DETAILS', 'FETCH_TAGS', 'FETCH_TAG_DETAILS']),
    async fetchCategoryDetails(){
      this.fetching = true
      if(this.$route.params.id != 0){
        try{
          let data = await this.FETCH_CATEGORY_DETAILS(this.$route.params.id)
          this.categoryData = data.data
          
        }catch(e){
          console.log(e);
        }
      }else{
        this.categoryData = this.allCategoryList
      }
      this.fetching = false
    },
    async fetchTags(){
      try{
        let data = await this.FETCH_TAGS()
        this.tagList = data.data
      }catch(e){
        console.log(e);
      }
    },
    goTo(url) {
      window.open(url, '_blank')
    },
    async fetchData(){
      this.fetching = true
      await this.fetchCategoryDetails()
      await this.fetchTags()
      this.fetching = false
    },
    async tagClick(id) {
      this.fetching = true
      try{
        let data = await this.FETCH_TAG_DETAILS(id)
        this.categoryData = data.data
      }catch(e){
        console.log(e);
      }
      this.fetching = false
    },
    DetailsTab(project){
      this.$store.commit("setSelectedProject", project)
      this.$router.push('/project/' + project.title)
    }
  },
  created(){
    this.fetchData()
  }
}
</script>

<style scoped>
.pointer{
  cursor: pointer;
}
</style>