<template>
  <v-container>
    <v-card
      class="mx-auto px-4 py-3 mb-16"
      width="800"
    >
      <v-card-title>Submit Project</v-card-title>
      <v-divider></v-divider>

      <v-text-field
        v-model="projectData.title"
        :rules="rules"
        label="Title"
        class="mt-5"
        required
      ></v-text-field>

      <v-select
        v-model="projectData.category"
        :items="TypeOfCategory"
        label="Category"
      ></v-select>

      <v-textarea
        v-model="projectData.introduction"
        :rules="rules"
        label="Short Introduction"
        required
      ></v-textarea>

      <v-textarea
        label="Detail Description"
        rows="3"
        row-height="30"
        v-model="projectData.description"
      ></v-textarea>

      <v-file-input
        v-model="projectData.image"
        accept="image/*"
        label="Logo (Image Size: 288*288 px)"
      ></v-file-input>

      <v-text-field
        v-model="projectData.website"
        label="Website"
      ></v-text-field>

      <v-text-field
        v-model="projectData.email"
        label="E-mail"
      ></v-text-field>

      <v-text-field
        v-model="projectData.twitter"
        label="Twitter"
      ></v-text-field>

      <v-text-field
        v-model="projectData.telegram"
        label="Telegram"
      ></v-text-field>

      <v-text-field
        v-model="projectData.discord"
        label="Discord"
      ></v-text-field>

      <v-text-field
        v-model="projectData.github"
        label="Github"
      ></v-text-field>

      <v-text-field
        v-model="projectData.coinmarketcap"
        label="Coinmarketcap"
      ></v-text-field>

      <v-textarea
        label="Smart Contract Addresses"
        rows="5"
        row-height="30"
        v-model="projectData.address"
      ></v-textarea>

      <v-text-field
        v-model="projectData.token"
        label="Token Symbol"
      ></v-text-field>

      <v-text-field
        v-model="projectData.token_address"
        label="Token Contract Address"
      ></v-text-field>

      <div>
        <vue-recaptcha sitekey="6Lcz3t8ZAAAAAGiVvwS9vVZ9pF8csR394SlB68j4"></vue-recaptcha>
      </div>

      <div class="d-flex justify-center mt-5 mb-5">
        <v-btn @click="submitProject">Submit</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
  data() {
    return {
      rules: [val => (val || '').length > 0 || 'This field is required'],
      projectData: {},
      TypeOfCategory: [
        'DeFi', 'Game', 'Tooling', 'Wallet', 'Validator', 'Workshop', 'Community'
      ]
    }
  },
  components: {
    VueRecaptcha
  },
  methods: {
    submitProject() {
      console.log(this.projectData, 'projectData');
    }
  },
  mounted() {
    const script = document.createElement("script")
    script.src = "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    document.body.appendChild(script)
  },
}
</script>

<style>

</style>