<template>
  <div>
    <Home />
  </div>
</template>

<script>
 
import Home from '@/components/main/Home'
export default {
  components:{
    Home
  },
  metaInfo: {
      title: 'BSCWrap',
      titleTemplate: '%s - All Binance Smart Chain (BSC) Projects wrapped in one site!',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    }
}
</script>

<style>

</style>